/** Статус цифрового оффера */
export const offerStatusTypes = {
	/** Статус оффера: создан и действителен */
	ACTIVE: 0,

	/** Статус оффера: принят соискателем */
	ACCEPTED: 1,

	/** Статус оффера: отклонён соискателем */
	REJECTED: 2,
} as const;

export type OfferStatusType = typeof offerStatusTypes[keyof typeof offerStatusTypes];
